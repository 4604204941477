<!--
 * ======================================
 * 说明： 历年班级评分记录
 * 作者： Silence
 * 文件： class.vue
 * 日期： 2023/6/7 0:08
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Main
    class="h-full"
    ref="main"
    :on-params="onParams"
    :columns="classColumns"
    :is-selection="false"
    :is-action="false"
    :is-page="false"
    api="/record/year_data"
  >
    <template #search>
      <Search
        v-model="searchForm"
        :options="classSearchOptions"
        @onSearch="onFind()"
      ></Search>
    </template>
    <template #table-expand="{ row }">
      <div v-if="row.data" class="flex p-2">
        <div class="w-20 text-center">
          <div class="p-2">评价维度</div>
          <div class="p-2">自评</div>
          <div class="p-2">互评</div>
          <div class="p-2">师评</div>
        </div>
        <template v-for="(item, index) in JSON.parse(row.data)">
          <div
            class="flex-1 text-center"
            :key="index"
            v-if="!isNaN(Number(index))"
          >
            <div class="p-2">{{ item["title"] }}</div>
            <div class="p-2">{{ item["self"] }}</div>
            <div class="p-2">{{ item["mutual"] }}</div>
            <div class="p-2">{{ item["teacher"] }}</div>
          </div>
        </template>
      </div>
    </template>
    <template #table-item-semester="{ row }">
      <template v-if="row.semester">
        {{ row.semester == 1 ? "第一学期" : "第二学期" }}
      </template>
      <template v-else>-</template>
    </template>
    <template #table-item-room_id="{ row }">
      <template v-if="row.level && row.name">
        {{ row.level
        }}{{ row.name && row.name.length > 1 ? row.name : "0" + row.name }} 班
      </template>
      <template v-else>-</template>
    </template>
    <template #table-item-data="{ row }">
      <template v-if="row.data">
        {{ Number(JSON.parse(row.data).total).toFixed(2) }}
      </template>
      <template v-else>-</template>
    </template>
  </Main>
</template>
<script>
import Main from "../components/main/index.vue";
import Search from "../components/search/index.vue";
import { classColumns, classSearchOptions } from "./config.js";
export default {
  name: "",
  components: { Main, Search },
  props: {
    classId: {
      type: Array,
      default: null,
    },
  },
  watch: {
    classId: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.room_id = [Number(val[0]), Number(val[1])];
          this.classSearchOptions.map((item) => {
            if (item.field === "room_id") {
              item["disabled"] = false;
            }
          });
        }
      },
    },
  },
  data() {
    return {
      classColumns,
      classSearchOptions,
      searchForm: {
        room_id: [7, 1],
        semesterYear: "2022",
        semester: 1,
      },
    };
  },
  computed: {},
  created() {},
  async mounted() {
     await this.getClassOptions();
  },
  methods: {
    onParams() {
      // this.searchForm["roomId"] =
      //   this.searchForm["room_id"][this.searchForm["room_id"].length - 1];
      this.searchForm.level = this.searchForm['room_id'][0];
      this.searchForm.name = this.searchForm['room_id'][1];
      return this.searchForm;
    },

    /**
     *  @actions:  查询列表数据
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/7 0:14
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    async onFind() {
      await this.$refs["main"].onFind();
    },

    /**
     *  @actions:  获取班级
     *  @content:
     *  @Autor: Silence
     *  @Date: 2023/6/11 23:55
     *  @params:
     *          <参数名称>  参数标题  参数类型    默认值
     **/
    // 获取班级待选项
    getClassOptions() {
      this.loading = true;
      this.$cloud
        .get("/room/list", {
          pagelndex: 1,
          pageSize: 999,
        })
        .then((res) => {
          this.loading = false;
          if (res.list && typeof res.list == "object" && res.list.length) {
            let arrays = [];
            res.list.map((item) => {
              let findIndex = null;
              arrays.map((part, index) => {
                if (part.value === item.level) {
                  findIndex = index;
                }
              });
              let label =
                String(item.level) +
                (item.name.length > 1 ? String(item.name) : "0" + item.name) +
                " 班";
              if (findIndex || findIndex === 0) {
                arrays[findIndex].children.push({
                  value: item["id"],
                  label: label,
                });
              } else {
                arrays.push({
                  label: item.level + "年级",
                  value: item.level,
                  children: [
                    {
                      value: item["id"],
                      label: label,
                    },
                  ],
                });
              }
            });

            this.classSearchOptions.map((item, index) => {
              if (item.field === "room_id") {
                item.options = arrays;
                this.classSearchOptions.splice(index, 1, item);
              }
            });
            console.log('this.classSearchOptions', this.classSearchOptions)

            this.onFind();
          }
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
