/**
 * ======================================
 * 说明： 历史记录
 * 作者： Silence
 * 文件： config.js
 * 日期： 2023/6/7 0:15
 * ======================================
 */
export const classColumns = [
  {
    field: "no",
    label: "学号",
    align: "left",
    width: "120",
  },
  {
    field: "username",
    label: "姓名",
    align: "left",
    width: "120",
  },
  {
    field: "semester_year",
    label: "学年",
    align: "center",
    width: "120,",
  },
  {
    field: "semester",
    label: "学期",
    align: "left",
    width: "140,",
  },
  {
    field: "room_id",
    label: "班级",
    align: "left",
    width: "160",
  },
  {
    field: "data",
    label: "总分",
    align: "left",
    width: "120",
  },
];

export const classSearchOptions = [
  {
    type: "cascader",
    field: "room_id",
    label: "班级",
    clearable: false,
    placeholder: "按班级名称查询",
    options: [
      {
        label: "7年级",
        value: 7,
        children: [
          {
            value: 1,
            label: "701 班",
          },
          {
            value: 2,
            label: "702 班",
          },
          {
            value: 3,
            label: "703 班",
          },
          {
            value: 4,
            label: "704 班",
          },
          {
            value: 5,
            label: "705 班",
          },
          {
            value: 6,
            label: "706 班",
          },
          {
            value: 7,
            label: "707 班",
          },
          {
            value: 8,
            label: "708 班",
          },
          {
            value: 9,
            label: "709 班",
          },
          {
            value: 10,
            label: "710 班",
          },
          {
            value: 11,
            label: "711 班",
          },
          {
            value: 12,
            label: "712 班",
          },
          {
            value: 13,
            label: "713 班",
          },
          {
            value: 14,
            label: "714 班",
          },
          {
            value: 15,
            label: "715 班",
          },
          {
            value: 16,
            label: "716 班",
          },
          {
            value: 17,
            label: "717 班",
          },
          {
            value: 18,
            label: "718 班",
          },{
            value: 19,
            label: "719 班",
          },
          {
            value: 20,
            label: "720 班",
          },
        ],
      },
      {
        label: "8年级",
        value: 8,
        children: [
          {
            value: 1,
            label: "801 班",
          },
          {
            value: 2,
            label: "802 班",
          },
          {
            value: 3,
            label: "803 班",
          },
          {
            value: 4,
            label: "804 班",
          },
          {
            value: 5,
            label: "805 班",
          },
          {
            value: 6,
            label: "806 班",
          },
          {
            value: 7,
            label: "807 班",
          },
          {
            value: 8,
            label: "808 班",
          },
          {
            value: 9,
            label: "809 班",
          },
          {
            value: 10,
            label: "810 班",
          },
          {
            value: 11,
            label: "811 班",
          },
          {
            value: 12,
            label: "812 班",
          },
          {
            value: 13,
            label: "813 班",
          },
          {
            value: 14,
            label: "814 班",
          },
          {
            value: 15,
            label: "815 班",
          },
          {
            value: 16,
            label: "816 班",
          },{
            value: 17,
            label: "817 班",
          },
          {
            value: 18,
            label: "818 班",
          },
          {
            value: 19,
            label: "819 班",
          },
          {
            value: 20,
            label: "820 班",
          },
        ],
      },
      {
        label: "9年级",
        value: 9,
        children: [
          {
            value: 1,
            label: "901 班",
          },
          {
            value: 2,
            label: "902 班",
          },
          {
            value: 3,
            label: "903 班",
          },
          {
            value: 4,
            label: "904 班",
          },
          {
            value: 5,
            label: "905 班",
          },
          {
            value: 6,
            label: "906 班",
          },
          {
            value: 7,
            label: "907 班",
          },
          {
            value: 8,
            label: "908 班",
          },
          {
            value: 9,
            label: "909 班",
          },
          {
            value: 10,
            label: "910 班",
          },
          {
            value: 11,
            label: "911 班",
          },
          {
            value: 12,
            label: "912 班",
          },
          {
            value: 13,
            label: "913 班",
          },
          {
            value: 14,
            label: "914 班",
          },
          {
            value: 15,
            label: "915 班",
          },
          {
            value: 16,
            label: "916 班",
          },
          {
            value: 17,
            label: "917 班",
          },
          {
            value: 18,
            label: "918 班",
          },
          {
            value: 19,
            label: "919 班",
          },
          {
            value: 20,
            label: "920 班",
          }
        ],
      }
    ],
  },
  {
    type: "year",
    field: "semesterYear",
    label: "学年",
    clearable: false,
    placeholder: "按学年查询",
  },
  {
    type: "select",
    field: "semester",
    label: "学期",
    clearable: false,
    placeholder: "按学期查询",
    options: [
      {
        name: "第一学期",
        id: 1,
      },
      {
        name: "第二学年",
        id: 2,
      },
    ],
  },
];

export const studentSearchOptions = [
  {
    type: "year",
    field: "semesterYear",
    label: "学年",
    placeholder: "按学年查询",
  },
  {
    type: "select",
    field: "semester",
    label: "学期",
    placeholder: "按学期查询",
    options: [
      {
        name: "第一学期",
        id: 1,
      },
      {
        name: "第二学年",
        id: 2,
      },
    ],
  },
];
